
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AppConfig } from '../../config/app.config';
import { RestService  } from '../core/rest.service';
const SUBJECTS = 'subjects';
const STUDY_SITES ='studySites';
const PROPOSAL_TYPES ='proposalTypes';
@Injectable()
export class JournalService {

  constructor(private restService: RestService) {
  }
  // api's for journals
  public getSubjects(journalId) {
    return this.restService.get(`${AppConfig.BASE_URL_V09}/journals/${journalId}/categories/${SUBJECTS}/tree`, null).pipe(map((res: any) => res));
  }
  public getStudySites(journalId) {
    return this.restService.get(`${AppConfig.BASE_URL_V09}/journals/${journalId}/categories/${STUDY_SITES}/tree`, null).pipe(map((res: any) => res));
  }
  public getproposalTypes(journalId) {
    return this.restService.get(`${AppConfig.BASE_URL_V09}/journals/${journalId}/categories/${PROPOSAL_TYPES}/tree`, null).pipe(map((res: any) => res));
  }

  // old api's we may need to remove unused api's latter
  public getJournals() {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/journal', null).pipe(map((res: any) => res));
  }
  public getJournalData(name) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/journal/' + name, null).pipe(map((res: any) => res));
  }
  public getJournalDetails(id) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/journal/content/' + id, null).pipe(map((res: any) => res));
  }
  public getJournalImage(id) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/journal/imagesource/' + id, null).pipe(map((res: any) => res));
  }
  public addJournal(formData) {
    return this.restService.post(AppConfig.BASE_URL_V1 + '/journal/', formData, null, true).pipe(map((res: any) => res));
  }
  public updateJournal(data, name) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/journal/' + name, data, null).pipe(map((res: any) => res));
  }
  public updateJournalDetails(data, id) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/journal/content/' + id, data,  false, false, true).pipe(map((res: any) => res));
  }
  public updateJournalImage(formData, id) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/journal/thumbnail/' + id, formData, null, true, true)
    .pipe(map((res: any) => res));
  }
  public getEmailTemplateById(emailTemplateNodeId) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/email/' + emailTemplateNodeId, true).pipe(map((res: any) => res));
  }
  public getEmailDetails(emailTemplateNodeId) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/email/details/' + emailTemplateNodeId, true).pipe(map((res: any) => res));
  }
  public getAllEmailTemplates(journalId, page) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/email/template/' + journalId, { page}, true).pipe(map((res: any) => res));
  }
  public updateEmailTemplate(emailTemplateNodeId, formData) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/email/' + emailTemplateNodeId, formData, false, true, true)
    .pipe(map((res: any) => res));
  }
  public onUpdateEmailDetails(emailTemplateNodeId, formData) {
    return this.restService.put(AppConfig.BASE_URL_V1 + '/email/details/' + emailTemplateNodeId, formData, false, true, true)
    .pipe(map((res: any) => res));
  }
  public addEmailTemplate(journalId,formData) {
    return this.restService.post(AppConfig.BASE_URL_V1 + '/email/' + journalId, formData, null, true, true).pipe(map((res: any) => res));
  }
  public getHelpDocuments(journalId) {
    return this.restService.get(AppConfig.BASE_URL_V1 + '/help/' + journalId, null).pipe(map((res: any) => res));
  }
  public uploadHelpDocuments(name , journalId, formData) {
    return this.restService.post(AppConfig.BASE_URL_V1 + '/help/uploadDocuments/' + journalId + '?name=' + name, formData, null, true, true)
    .pipe(map((res: any) => res));
  }
}
