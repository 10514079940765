import { Injectable } from "@angular/core";
import { AppConfig } from "config/app.config";
import { map } from "rxjs";
import { ArticleModel } from "src/app/model/article.model";
import { RestService } from "src/core/rest.service";
@Injectable()
export class ArticleService {

  constructor(private restService: RestService) { }

  getMyArticles(journalId,page = 1) {
    return this.restService.get(`${AppConfig.BASE_URL_V09}/journals/${journalId}/articles/mysubmissions?page=${page}`, null).pipe(map((res: any) => res));
  }
  create(journalId,articleInfo: ArticleModel) {
    return this.restService.post(`${AppConfig.BASE_URL_V09}/journals/${journalId}/articles/`, articleInfo, null).pipe(map((res: any) => res));
  }
  getArticlesById(journalId,articleId) {
    return this.restService.get(`${AppConfig.BASE_URL_V09}/journals/${journalId}/articles/${articleId}`, null).pipe(map((res: any) => res));
  }
  update(journalId:string,articleId: string, articleInfo: ArticleModel) {
    return this.restService.put(`${AppConfig.BASE_URL_V09}/journals/${journalId}/articles/${articleId}`, articleInfo, false).pipe(map((res: any) => res));
  }
  getArticleTasks(journalId, articleId) {
    return this.restService.get(`${AppConfig.BASE_URL_V09}/journals/${journalId}/articles/${articleId}/workflows`, null).pipe(map((res: any) => res));
  }
  resubmitArticle(journalId, articleId,articleInfo) {
    return this.restService.post(`${AppConfig.BASE_URL_V09}/journals/${journalId}/articles/${articleId}/revise`,articleInfo, null).pipe(map((res: any) => res));
  }
  public uploadAttachment(journalId,articleId, formData) {
    return this.restService
      .post(`${AppConfig.BASE_URL_V09}/journals/${journalId}/articles/${articleId}/attachments`, formData, null, true, false).
      pipe(map((res: any) => res));
  }
  public getAllAttachments(journalId,articleId) {
    return this.restService
      .get(`${AppConfig.BASE_URL_V09}/journals/${journalId}/articles/${articleId}/attachments`, null).
      pipe(map((res: any) => res));
  }
  public deleteAttachment(journalId,articleId, fileName) {
    return this.restService
      .delete(`${AppConfig.BASE_URL_V09}/journals/${journalId}/articles/${articleId}/attachments/${fileName}`, null).
      pipe(map((res: any) => res));
  }
  public getAttachmentContent(journalId,articleId, filename) {
    return this.restService
      .get(`${AppConfig.BASE_URL_V09}/journals/${journalId}/articles/${articleId}/attachments/${filename}`, null, false, true, true).
      pipe(map((res: any) => res));
  }
  submitArticle(journalId:string,articleId: string, articleInfo: ArticleModel) {
    return this.restService.post(`${AppConfig.BASE_URL_V09}/journals/${journalId}/articles/${articleId}/submit`, articleInfo, null).pipe(map((res: any) => res));
  }
  getWorkflowComment(journalId,articleId) {
    return this.restService.get(`${AppConfig.BASE_URL_V09}/journals/${journalId}/articles/${articleId}/comments`, null).pipe(map((res: any) => res));
  }
  public getReviewerManuscripts(journalId,articleId) {
    return this.restService
      .get(`${AppConfig.BASE_URL_V09}/journals/${journalId}/articles/${articleId}/reviewers/manuscripts`, null).
      pipe(map((res: any) => res));
  }
  public getManuscriptContent(journalId,articleId, reviewerId,cycle) {
    return this.restService
      .get(`${AppConfig.BASE_URL_V09}/journals/${journalId}/articles/${articleId}/reviewers/${reviewerId}/manuscript/${cycle}`, null, false, true, true).
      pipe(map((res: any) => res));
  }
}